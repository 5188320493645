.ant-btn {
	border: none;
	// color: #363636;
	font-weight: 500;
	text-shadow: none;
	box-shadow: none;
	border-radius: 4px;
	padding: 0 12px;
	&:hover {
		color: #363636;
	}
	&:focus {
		color: #363636;
	}
	&:disabled {
		color: $txt-color-gray2;
		background: $bg-disabled !important;
		border: 1px solid $bd-disabled;
	}
	&:active {
		background: none;
	}
	&.ant-btn:not([disabled]) {
		&:active {
			box-shadow: none;
		}
	}

	[ant-click-animating-without-extra-node='true']::after {
		box-shadow: inset;
	}
}

.btn-default-rounded {
	background-color: $bg-color-primary !important;
	color: #363636 !important;
	&:hover {
		color: #363636 !important;
		background-color: #ffeb97 !important;
	}
	&:focus {
		color: #363636;
		background-color: #ffe061 !important;
	}
}

// .btn-default-rounded:hover {

// }
// .btn-default-rounded.ant-btn:focus {

// }

.btn-default-outlined {
	color: #363636;
	// background-color: white !important;
	background: none !important;
}
.btn-default-outlined.ant-btn:focus {
	color: #363636;
}
.btn-default-outlined:hover {
	color: #363636;
	background-color: #fffde6 !important;
}

.btn-default-disable {
	border: 1px solid #707070;

	&:hover {
		background-color: #707070;
		color: $txt-color-white;
		border: 1px solid #707070;
	}
}

.btn-default-outlinded-dark {
	border: 1px solid #fcd535;
	background-color: #121518;
	color: #fcd535;
	&:hover {
		background-color: #ffeb97;
		color: #1a1a1a;
	}
	&:focus {
		// background-color: #fcd535;
		color: #fcd535;
		background: none;
	}
}

.btn-primary-rounded {
	background-color: #ffedef;
	color: #ff4559;
	&:hover {
		background-color: #ffedef !important;
		color: #ff4559 !important;
	}
}

.btn-outlined.ant-btn:not([disabled]):active {
	color: #363636;
	background-color: #ffcd00 !important;
}
.btn-remove {
	font-size: $txt-size-h7;
	color: $txt-color-black;

	&:hover {
		color: $txt-color-red;
	}
}

btn-info-outlined {
	// line-height: 32px;
	// padding: 0 15px;
}

// .ant-btn-background-ghost.ant-btn-primary:hover,
// .ant-btn-background-ghost.ant-btn-primary:focus {
// 	background-color: $bg-color-primary;
// 	color: $txt-color-white;
// }

// .btn_cancel {
// 	&:hover {
// 		background-color: #707070;
// 		color: $txt-color-white;
// 		border: 1px solid #707070;
// 	}
// }

// .btn_check {
// 	color: $txt-color-green !important;
// 	border: 1px solid $bg-color-green !important;
// 	border-radius: 4px;
// 	&:focus {
// 		background-color: transparent !important;
// 		color: $txt-color-green !important;
// 		border: 1px solid $bg-color-green !important;
// 	}

// 	&:hover {
// 		background-color: $bg-color-green !important;
// 		color: $txt-color-white !important;
// 		border: 1px solid $bg-color-green !important;
// 	}
// }

// .ant-btn-background-ghost.ant-btn-primary[disabled] {
// 	color: rgba(0, 0, 0, 0.25) !important;
// 	border-color: #d9d9d9 !important;

// 	&:hover {
// 		color: rgba(0, 0, 0, 0.25) !important;
// 		border-color: #d9d9d9 !important;
// 		background-color: #f5f5f5 !important;
// 	}
// }

// .checkbox-custom {
// 	.ant-checkbox-checked .ant-checkbox-inner {
// 		background: #09b8af;
// 		border-radius: 4px;
// 		border: none;
// 	}

// 	.ant-checkbox-checked:hover {
// 		border-color: #09b8af;
// 	}
// }

// .btn-re-order {
// 	border-radius: 4px;
// 	background: #ffffff;
// 	color: #008df9;
// 	border: 1px solid #80c8ff;

// 	&:hover {
// 		background-color: #008df9;
// 		color: white;
// 		border-color: unset;
// 	}
// }

// .btn-remove-order {
// 	border-radius: 4px;
// 	background: #ffffff;
// 	color: #ff4559;
// 	border: 1px solid #ffb2ba;

// 	&:hover {
// 		background-color: #ff4559;
// 		color: white;
// 		border-color: unset;
// 	}
// }

// .btn-report-order {
// 	border-radius: 4px;
// 	background: #ffffff;
// 	color: #363636;
// 	border: 1px solid #c7c7c7;

// 	&:hover {
// 		background-color: $bg-color-primary;
// 		color: white;
// 		border-color: unset;
// 	}
// }

// .hover-primary {
// 	&:hover {
// 		background-color: $txt-color-orange;
// 		color: white;
// 		border: none;
// 	}
// }

// .search-packages {
// 	color: white;
// 	background-color: #3340b6 !important;
// 	&:hover {
// 		color: white !important;
// 		box-shadow: 0px 0px 0px 3px #ced1f3 !important;
// 	}

// 	&.ant-btn:not([disabled]):active,
// 	&:focus {
// 		color: white;
// 		background-color: #3340b6 !important;
// 	}
// }

.btn-create-success {
	color: white;
	background-color: #3340b6 !important;
	border: none;
	&:hover {
		color: white !important;
		box-shadow: 0px 0px 0px 3px #ced1f3 !important;
		border: none;
	}

	&.ant-btn:not([disabled]):active,
	&:focus {
		color: white;
		background-color: #3340b6 !important;
	}
}

// .btn-create-delivery {
// 	color: white;
// 	background-color: #faa200;
// 	border: 1px solid #ffa600;
// 	padding: 2px 12px;
// 	border-radius: 3px;
// 	&:hover {
// 		background-color: #faa200;
// 		color: white;
// 		box-shadow: 0px 0px 0px 3px #ffe3c0;
// 	}
// 	&.ant-btn:not([disabled]):active,
// 	&:focus {
// 		color: white;
// 		background-color: #faa200;
// 	}
// }

// .btn-outlined-orange {
// 	border: 1px solid #ffa600;
// 	color: $txt-color-orange1;
// 	height: 32px;
// 	align-items: center;
// 	background-color: #ffffff;

// 	a {
// 		color: #ffa600;
// 	}

// 	&:hover {
// 		color: #ffffff;
// 		background-color: #faa200;
// 		border: 1px solid #ffa600;
// 		box-sizing: border-box;
// 		box-shadow: 0 0 0 3px #ffe3c0;
// 	}
// }

// .btn-create-claim-by-order {
// 	& * {
// 		color: #faa200;
// 	}

// 	&:hover {
// 		& * {
// 			color: #fff;
// 		}
// 	}
// }

// .btn-create-thread-chat-by-order {
// 	border: 1px solid #008df9;
// 	color: #008df9;
// 	height: 32px;
// 	align-items: center;
// 	background-color: #ffffff;

// 	&:hover {
// 		border: 1px solid #008df9;
// 		color: #fff;
// 		background-color: #008df9;
// 		box-sizing: border-box;
// 	}

// 	span {
// 		padding-left: 5px;
// 	}
// }

// .btn-create-claim-by-order {
// 	& * {
// 		color: #faa200;
// 	}
// 	&:hover {
// 		& * {
// 			color: #fff;
// 		}
// 	}
// }

// // guide-line
// .btn-df {
// 	border-radius: 4px;
// 	font-family: $robotofont;
// 	box-shadow: none;
// 	text-transform: capitalize;
// 	padding: 4px 12px;
// 	&:disabled {
// 		color: $txt-color-gray2;
// 		background: $bg-disabled;
// 		border: 1px solid $bd-disabled;
// 	}

// 	&.ant-btn:not([disabled]) {
// 		&:active {
// 			box-shadow: none;
// 		}
// 	}

// 	[ant-click-animating-without-extra-node='true']::after {
// 		box-shadow: inset;
// 	}

// 	&-primary {
// 		&.ant-btn:not([disabled]) {
// 			background-color: $bg-color-primary;
// 			color: #fff;
// 			border: 1px solid $txt-color-primary;
// 			border-radius: 4px;
// 			&:hover {
// 				background-color: #fff;
// 				color: $txt-color-primary;
// 				box-shadow: inset;
// 			}
// 		}
// 		&-bg-white,
// 		&-bg-orange {
// 			&.ant-btn:not([disabled]) {
// 				background-color: #fff;
// 				color: $bg-color-primary;
// 				border: 1px solid $txt-color-primary;
// 				border-radius: 4px;
// 				&:hover {
// 					background-color: $bg-color-orange3;
// 					color: #fff;
// 					box-shadow: 0px 0px 0px 3px $box-sd-primary;
// 				}
// 			}
// 		}
// 		&-bg-orange {
// 			&.ant-btn:not([disabled]) {
// 				background-color: $bg-color-orange3;
// 				color: #fff;
// 			}
// 		}
// 	}

// 	&-secondary-bg-blue,
// 	&-warning {
// 		&.ant-btn:not([disabled]) {
// 			&:hover {
// 				background-color: $bg-color-blue7;
// 				color: #fff;
// 				box-shadow: 0px 0px 0px 3px $box-sd-secondary;
// 				& > i {
// 					color: white;
// 				}
// 			}
// 		}
// 	}
// 	&-secondary-bg-lemon {
// 		&.ant-btn:not([disabled]) {
// 			&:hover {
// 				background-color: $txt-color-orange2;
// 				color: #363636;
// 				// box-shadow: 0px 0px 0px 3px $txt-color-orange2;
// 			}
// 		}
// 	}

// 	&-secondary-bg-white {
// 		&.ant-btn:not([disabled]) {
// 			background-color: #fff;
// 			color: $bg-color-blue7;
// 			border: 1px solid $bg-color-blue7;
// 			text-transform: capitalize;
// 		}
// 	}

// 	&-secondary-bg-blue {
// 		&.ant-btn:not([disabled]) {
// 			background-color: $bg-color-blue7;
// 			color: #fff;
// 			border: none;
// 		}
// 	}
// 	&-secondary-bg-lemon {
// 		&.ant-btn:not([disabled]) {
// 			background-color: $txt-color-orange2;
// 			color: #363636;
// 			border: none;
// 		}
// 	}

// 	&-warning-bg-white {
// 		transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
// 		&.ant-btn:not([disabled]) {
// 			background-color: #fff;
// 			color: $txt-warning;
// 			border: 1px solid $bd-warning;
// 			&:hover {
// 				background-color: $bg-warning;
// 				color: #fff;
// 				box-shadow: 0px 0px 0px 3px $box-sd-warning;
// 			}
// 		}
// 	}

// 	&-tertiary {
// 		background-color: #fff;
// 		border: 1px solid #707070;
// 		text-transform: capitalize;
// 		color: $txt-color-black;
// 		&.ant-btn:not([disabled]) {
// 			&:hover,
// 			&:focus,
// 			&:target {
// 				cursor: pointer;
// 				background-color: #fff;
// 				border: 1px solid #707070;
// 				color: $txt-color-black;
// 			}
// 			&[ant-click-animating-without-extra-node='true']::after {
// 				box-shadow: 0px 0px 0px 3px $bg-color-gray-tab !important;
// 			}
// 		}
// 		&-bg-white {
// 			background-color: #fff;
// 			border: 1px solid #c7c7c7;
// 			color: $txt-color-black;

// 			&.ant-btn:not([disabled]) {
// 				&:hover,
// 				&:focus,
// 				&:target {
// 					cursor: pointer;
// 					background-color: #707070;
// 					border: 1px solid #c7c7c7;
// 					color: white;
// 				}

// 				&[ant-click-animating-without-extra-node='true']::after {
// 					box-shadow: 0px 0px 0px 3px $bg-color-gray-tab !important;
// 				}
// 			}
// 		}
// 	}

// 	&-quaternary {
// 		&-bg-red {
// 			background-color: $bd-warning;
// 			border: 1px solid $box-sd-warning;
// 			color: white;
// 			&.ant-btn:not([disabled]) {
// 				&:hover,
// 				&:focus,
// 				&:target {
// 					cursor: pointer;
// 					background-color: $bd-warning;
// 					border: 1px solid $box-sd-warning;
// 					color: white;
// 					box-shadow: 0px 0px 0px 2px $box-sd-warning;
// 				}

// 				&[ant-click-animating-without-extra-node='true']::after {
// 					box-shadow: 0px 0px 0px 2px $box-sd-warning;
// 				}
// 			}
// 		}
// 		&-bg-white {
// 			background-color: white;
// 			border: 1px solid #ffb2ba;
// 			color: #ff4559;
// 			&.ant-btn:not([disabled]) {
// 				&:hover,
// 				&:focus,
// 				&:target {
// 					cursor: pointer;
// 					background-color: #ff4559;
// 					border: 1px solid #ffb2ba;
// 					color: white;
// 					// box-shadow: 0px 0px 0px 2px #FFB2BA;
// 				}

// 				&[ant-click-animating-without-extra-node='true']::after {
// 					box-shadow: 0px 0px 0px 3px $bg-color-gray-tab !important;
// 				}
// 			}
// 		}
// 	}
// }

// .m24-button {
// 	border-radius: 4px;
// 	font-size: 14px;
// 	border: 1px solid #c7c7c7;
// 	-webkit-border-radius: 4px;
// 	-moz-border-radius: 4px;
// 	display: inline-flex;
// 	align-items: center;

// 	&:hover,
// 	&:active {
// 		border-color: #c7c7c7;
// 		color: #363636;
// 	}

// 	&.ant-btn-primary {
// 		background: #3340b6;
// 		border: none;
// 		color: #ffffff;

// 		&:hover,
// 		&:active,
// 		&:focus {
// 			&:not(:disabled) {
// 				background: #3340b6;
// 				color: #ffffff;
// 				box-sizing: border-box;
// 				box-shadow: 0 0 0 3px #ced1f3;
// 			}
// 		}

// 		&:disabled {
// 			background: #ebebeb;
// 			color: #bdbdbd;
// 			border: 1px solid #c7c7c7;
// 		}
// 	}

// 	&.ant-btn-default {
// 		&:hover {
// 			background-color: #707070;
// 			color: #ffffff;
// 			border: 1px solid #707070;
// 		}
// 	}

// 	&.bordered-btn {
// 		background-color: #fff;
// 		color: #3340b6;
// 		border: 1px solid #3340b6;

// 		&:disabled {
// 			border: none;
// 		}
// 	}
// }

