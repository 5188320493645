.float-label {
	position: relative;
	& .label,
	& .label-range {
		position: absolute;
		font-weight: 400;
		font-size: 14px;
		line-height: 22px;
		color: #7d7e7e;
		top: 50%;
		transform: translate(0, -50%);
		left: 12px;
		transition: 0.2s ease all;
		pointer-events: none;
		&-float {
			top: -12px;
			transform: translate(0, 0);
			background-color: white;
			padding: 0 3px;
			font-size: 12px;
		}
	}

	&.dark {
		& .label,
		& .label-range {
			&-float {
				background-color: #121518;
				color: white;
			}
		}
	}

	& .label-range {
		left: 50%;
	}
}

