.connect-market-container {
	.ant-tabs-top > .ant-tabs-nav {
		margin: 0 0 0 0;
	}

	.ant-tabs-nav-wrap {
		height: 32px;
		background-color: #ffffff;
		border-radius: 6px 6px 0 0;
		-webkit-border-radius: 6px 6px 0 0;
		-moz-border-radius: 6px 6px 0 0;
	}

	.ant-tabs-nav-list {
		background-color: #ffffff;
		.ant-tabs-tab {
			background: #ffffff;
			border: transparent !important;
			padding: 6px 32px;

			.ant-tabs-tab-btn {
				color: #3340b6;
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				border-radius: 12px 12px 0 0;
				-webkit-border-radius: 12px 12px 0 0;
				-moz-border-radius: 12px 12px 0 0;
			}

			&-active {
				background-color: #ecedf8;
			}
		}
	}

	.ant-tabs-content-holder {
		background: #ffffff;
		box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
		border-radius: 0 0 6px 6px;
	}

	.shop-list-header {
		padding: 8px 16px 0 16px;
		background-color: #ffffff;
		display: flex;
		flex-direction: row;
		width: 100%;
		justify-content: space-between;
		align-items: center;

		.title {
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			color: #1a1a1a;
			text-transform: capitalize;
		}

		.badge {
			border-radius: 20px;
			-moz-border-radius: 20px;
			-webkit-border-radius: 20px;
			padding: 0 8px;
			background: #faa200;
			color: #ffffff;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: flex-start;
			font-size: 12px;
		}

		// button {
		// 	border-radius: 4px;
		// 	-webkit-border-radius: 4px;
		// 	-moz-border-radius: 4px;
		// 	padding: 5px 16px;
		// 	justify-content: center;
		// 	align-items: center;
		// 	font-style: normal;
		// 	font-weight: 500;
		// 	font-size: 14px;
		// 	text-transform: capitalize;
		// 	background: #3340b6;
		// 	color: #ffffff;
		// 	border: 1px solid transparent !important;
		// 	&:hover {
		// 		box-sizing: border-box;
		// 		box-shadow: 0 0 0 3px #ced1f3;
		// 	}
		// }
	}

	.shop-list {
		padding: 12px;
		background-color: #ffffff;

		thead[clas*='ant-table-thead'] tr {
			border-radius: 4px 4px 0 0;
			-webkit-border-radius: 4px 4px 0 0;
			-moz-border-radius: 4px 4px 0 0;
		}

		thead[class*='ant-table-thead'] th {
			padding: 5px 12px;
			border: none;
			background-color: #f4f6f8;
			font-family: Roboto, sans-serif;
			font-weight: 500;
			font-size: 12px;
			&:before {
				opacity: 0;
			}
		}

		.status-badge {
			//display: flex;
			align-items: center;
			border-radius: 25px;
			-webkit-border-radius: 25px;
			-moz-border-radius: 25px;
			padding: 0 12px;
			color: #ffffff;
		}

		.custom-row-hover:hover td {
			background: #feefd8 !important;
		}

		.empty-description {
			display: flex;
			flex-direction: column;
		}
	}
}

.connect-shop-modal {
	background: #ffffff;
	border-radius: 4px;
	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;
	padding: 0 0 0 0;

	.ant-modal-content .ant-modal-header {
		padding: 12px 0;
		border: none;
	}

	.ant-modal-title {
		//padding: 0;
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		text-transform: capitalize;
	}

	&-input {
		display: flex;
		flex-direction: row;
		align-items: center;
		width: 100%;
		height: auto;
		span {
			width: 25%;
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			color: #1a1a1a;
		}
		input {
			background: #ffffff;
			border: 1px solid #c7c7c7;
			box-sizing: border-box;
			border-radius: 4px;
			-webkit-border-radius: 4px;
			-moz-border-radius: 4px;
			&::-webkit-input-placeholder {
				font-style: normal;
				font-weight: 400;
				font-size: 14px;
				color: #bdbdbd;
			}
		}
	}

	.ant-modal-footer {
		padding: 0 0 12px 0;
		// button {
		// 	width: 20%;
		// 	padding: 5px 16px;
		// 	border: 1px solid;
		// 	border-radius: 4px;
		// 	background: #ffffff;
		// 	font-family: 'Roboto', sans-serif;
		// 	font-weight: 400;
		// 	font-size: 14px;
		// 	justify-content: center;
		// 	align-items: center;

		// 	&.submit {
		// 		border: 1px solid #3340b6;
		// 		background-color: #3340b6;
		// 		padding-left: 10px;
		// 		color: #ffffff;
		// 		&:hover {
		// 			box-shadow: 0 0 0 3px #ced1f3;
		// 		}

		// 		&:disabled {
		// 			background: #f5f5f5;
		// 			border: 1px solid #c7c7c7;
		// 			box-sizing: border-box;
		// 			border-radius: 4px;
		// 			color: #bdbdbd;
		// 			&:hover {
		// 				box-shadow: none !important;
		// 			}
		// 		}
		// 	}

		// 	&.cancel {
		// 		border: 1px solid #c7c7c7;
		// 		color: #1a1a1a;

		// 		&:hover {
		// 			background-color: #707070;
		// 			color: #ffffff;
		// 			border: 1px solid #707070;
		// 		}
		// 	}
		// }
	}
}

