._m24-pagination {
	.ant-pagination-item {
		border-radius: 6px;
	}
	.ant-pagination-item-active {
		background-color: #FCD535;
		//border: 1px solid #CC8700;
		a {
			color: #363636;
			:active {
				color: white;
			}
			&:hover {
				color: white;
			}
		}
	}
}

._m24-pagination {
	.ant-pagination-item-link {
		border-radius: 6px !important;
	}
	& .ant-pagination-item-active {
		border: none;
	}
}

._m24-pagination {
	.ant-pagination-disabled .ant-pagination-item-link {
		background-color: #ebebeb;
	}
}

._size-changer {
	&:not(.ant-select-customize-input) {
		div {
			&.ant-select-selector {
				border-radius: 4px;
				border-color: #ebebeb;
			}
		}
	}
}

.ant-select._size-changer {
	margin-right: 16px;
}

._m24-pagination-dark {
	.ant-select:not(.ant-select-customize-input) .ant-select-selector {
		border-color: none;
		color: #e6e6e6;
		background-color: #32373e;
	}
	.ant-select-arrow .anticon {
		color: #e6e6e6;
	}
	.ant-pagination-prev:hover .ant-pagination-item-link {
		color: #e6e6e6;
		border-color: none;
	}
	.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
		border: none;
	}
	.ant-pagination-disabled .ant-pagination-item-link {
		color: #ffffff;
		background-color: #4d545f;
	}
	.ant-pagination-item-link {
		color: #ffffff !important;
		border-color: none !important;
		background-color: #4d545f;
		border-radius: 6px !important;
	}
	& .ant-pagination-item-active {
		border: none;
	}
	.ant-pagination-item {
		border-radius: 6px;
		background-color: #32373e;
	}
	.ant-pagination-item:hover {
		border-color: none !important;
	}
	.ant-pagination-item a {
		color: #ffffff;
	}
	.ant-pagination-item:hover a {
		border: none !important;
		// color: #ffffff;
	}

	.ant-pagination-item-active {
		background-color: #FFEB97;
		//border: 1px solid #CC8700;
		a {
			color: #32373e;
			:active {
				color: white;
			}
		}
	}
	.ant-pagination-next:hover button {
		border-color: none !important;
	}
}

