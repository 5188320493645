.ant-checkbox-inner {
	border: 1px solid #c0c0c0;
}
.ant-checkbox + span {
	padding-left: 4px;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
	border-color: $bg-color-primary;
}
.ant-checkbox-checked::after {
	border: 1px solid $bg-color-primary;
}

.ant-checkbox {
	&.ant-checkbox-indeterminate {
		&.ant-checkbox-checked {
			& .ant-checkbox-inner {
				background-color: #fff !important;

				&::after {
					background-color: $bg-color-primary !important;
				}
			}
		}
	}
	&.ant-checkbox-checked {
		&.ant-checkbox-disabled {
			& .ant-checkbox-inner {
				// background-color: #f5f5f5;
				border-color: #d9d9d9;
			}
		}
		& .ant-checkbox-inner {
			border-color: $bg-color-primary;
			background-color: $bg-color-primary;
		}
	}
}

.checkbox-dark {
	.ant-checkbox-inner {
		background-color: #1a1a1a;
	}
	.ant-checkbox-checked {
		.ant-checkbox-input {
			color: black !important;
		}
	}
	.ant-checkbox-checked .ant-checkbox-inner::after {
		border: 2px solid #1a1a1a;
		position: absolute;
		display: table;
		border-top: 0;
		border-left: 0;
		transform: rotate(45deg) scale(1) translate(-50%, -50%);
		opacity: 1;
		transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
		content: ' ';
	}
}

