.package-modal {
  .purchase-step-header {
    display: flex;
    padding: 12px 24px 12px 24px;

    .ant-steps-item-content > .ant-steps-item-title::after {
      background-color: #C7C7C7;
      //width:10px;
      //right:50px
    }

    .ant-steps-item-title {
      font-size:12px;
      font-weight: 400;
    }
    .ant-steps-item {
      //padding: 0 0 0 8px;
    }
    .ant-steps-item-icon {
      text-align: center;
      align-items: center;
    }
  }

  .package-list {
    padding: 4px 0 0 0;
  }
  .package-list-footer {
    display: flex;
    justify-content: flex-end;
    padding: 12px 0 0 0;
    button {
      width: 16%;
      padding: 5px 16px;
      border: 1px solid;
      border-radius: 4px;
      background: #FFFFFF;
      font-family: 'Roboto', sans-serif;
      font-weight: 400;
      font-size: 14px;

      &.purchase {
        border: 1px solid #3340B6;
        color: #3340B6;

        &:hover {
          background-color: #3340b6;
          color: #FFFFFF;
          box-shadow: 0 0 0 3px #CED1F3;
        }

        &:disabled {
          background: #F5F5F5;
          border: 1px solid #C7C7C7;
          box-sizing: border-box;
          border-radius: 4px;
          color: #BDBDBD;

          &:hover {
            box-shadow: none !important;
          }
        }
      }

      &.cancel {
        border: 1px solid #C7C7C7;
        color: #1A1A1A;

        &:hover {
          background-color: #707070;
          color: $txt-color-white;
          border: 1px solid #707070;
        }
      }
    }
  }

  //payment-popup
  .payment-list {
      padding: 12px 8px;

      .payment-list-item {
        border: none;

        .ant-row {
          display: flex;
        }

        .ant-col {
          align-items: center;
          padding: 5px 0 5px 10px;
          display: grid;
        }

        .payment-list-item-content {
          height: 100%;
          background: #F4F6F8;
          border: 1px solid transparent !important;
          -webkit-border-radius: 6px;
          -moz-border-radius: 6px;
          border-radius: 6px;
          padding: 16px 32px;
          gap: 32px;
          font-weight: 500;
          font-size:  14px;

          &:hover {
            background: #ECEDF8;
            cursor: pointer;
          }

          &-selected {
            border: 1px solid transparent!important;
            box-shadow: 0 0 0 4px #ECEDF8;
            border-radius: 6px;
            background: #ECEDF8;
          }
          &__disabled {
            cursor: not-allowed!important;
            //pointer-events: none;
            color: #BDBDBD;
            background-color: #F4F6F8;
          }
          &__disabled:hover {
            color: #BDBDBD;
            background-color: #F4F6F8;
          }
        }
      }
  }
  .benefit-sharing-container {

    .benefit-sharing-inputs {
      font-weight: 400;
      font-size: 14px;

      .ant-input {
        border: 1px solid #C7C7C7!important;
        border-radius: 4px!important;
        padding: 3px 3px 3px 9px;
        gap: 8px;
        height: 26px;

        &::-webkit-input-placeholder {
          font-weight: 400 !important;
          font-family: Roboto, sans-serif;
          color: #BDBDBD !important;
          font-size: 12px !important;
        }
      }

      .ant-select-selector {
        border: 1px solid #C7C7C7;
        box-sizing: border-box !important;
        border-radius: 4px !important;
        gap: 8px;
        height: 26px;
        display: flex;
        width: 100%;

        .ant-select-selection-placeholder {
          font-weight: 400 !important;
          font-family: Roboto, sans-serif;
          color: #BDBDBD !important;
          font-size: 12px !important;
          //text-align: center;
          align-self:center;
        }

        .ant-select-selection-item {
          width:100%;
          align-items: center;
          height: 26px;
          div {
            height:26px;
          }
          .ant-divider {
            height: 14px;
          }
        }
      }

      .ant-spin-nested-loading {
        width: 100%;
      }
    }

    .connect-supplier-btn {
      justify-content: center;
      align-items: center;
      padding: 5px 16px;
      gap: 8px;
      background: #3340B6;
      border-radius: 4px;
      border: 1px solid transparent!important;
      color: #FFFFFF;
      &:hover {
        color:#FFFFFF;
        box-shadow: 0 0 0 3px #CED1F3;
      }
    }
  }

}


