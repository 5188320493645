.m22-picker.ant-picker {
	& .ant-picker-input {
		padding-left: 0;
		padding-right: 0;
		&-active {
			& > input:placeholder-shown {
			}
		}
	}
}
