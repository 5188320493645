.rich-text-container {
  &-focused {
    .tox.tox-tinymce {
      border-color: #ffce63 !important;
      box-shadow: 0 0 0 2px rgb(251 183 57 / 20%);
      border-right-width: 1px;
      outline: 0;
    }
  }

  .tox {
    &.tox-tinymce {
      border: 1px solid #c7c7c7;
      border-radius: 4px;
      transition: all 0.3s;

      &:hover {
        border-color: #ffce63;
      }
    }

    &:not(.tox-tinymce-inline) .tox-editor-header {
      padding: 0;
    }

    .tox-statusbar__path-item {
      display: none;
    }

    .tox-tbtn {
      margin: 1px 2px 1px 0px;
    }

    .tox-statusbar {
      display: none;
    }
  }

  .tox-statusbar__branding,
  .tox-statusbar__path-divider {
    display: none !important;
  }

  &.validate-error {
    .tox-tinymce {
      border-color: #ff4d4f !important;
    }
  }

  &.error-focus {
    .tox-tinymce {
      box-shadow: 0 0 0 2px rgb(255 77 79 / 20%);
    }
  }
}
