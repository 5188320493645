.cart-list {
	height: calc(100vh - 260px);
	overflow-y: auto;
	scroll-behavior: smooth;

	// & .ant-list-item {
	// 	padding: 12px;
	// 	&:hover {
	// 		background-color: #ffeed9;
	// 	}
	// }
}
