.tabs-light {
	.tab-slide {
	}
	.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
		color: #5a5a5a;
	}
	.ant-tabs-tab:hover {
		color: #5a5a5a;
	}

	.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab {
		padding: 5px 8px;
		border-top-left-radius: 6px;
		border-top-right-radius: 6px;
		background-color: #f6f6f6;
	}
	.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
		color: #7d7e7e;
		// padding: 0px 8px;
	}
	.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active {
		background-color: #fcd535 !important;
		padding: 5px 8px;
		.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
			color: #1a1a1a;
		}
	}
	.tab-card {
		.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
			color: #363636 !important;
		}
	}
	.tab-vertical.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active {
		background-color: #fcd535;
		border-top-left-radius: 6px;
		border-bottom-left-radius: 6px;
		color: #1a1a1a !important;
	}
	.tab-vertical.ant-tabs-card.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab {
		border-top-left-radius: 6px;
		border-bottom-left-radius: 6px;
		margin-top: 16px;
		color: #1a1a1a;
	}
}
.tabs-dark {
	.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
		border-bottom: 1px solid #1f2328;
	}

	.ant-tabs-content {
		color: white;
	}
	.ant-tabs-tab {
		border: none !important;
	}
	.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab {
		padding: 5px 8px;
		border-top-left-radius: 6px;
		border-top-right-radius: 6px;
	}
	.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
		background-color: #32373e;
		
	}
	.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active {
		background-color: #fcd535 !important;
		// .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
		// 	color: #363636 !important;
		// }
	}
	.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
		// color: #363636 !important;
	}
	.ant-tabs-tab:hover {
		color: #666666;
	}
	.tab-card {
		.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
			color: #363636 !important;
		}
	}
	.tab-vertical.ant-tabs-card.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
		border-top-left-radius: 6px;
		border-bottom-left-radius: 6px;
		margin-top: 16px;
		color: #666666;
	}
	.tab-vertical.ant-tabs-card.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab-active {
		background-color: #fcd535;
		border-top-left-radius: 6px;
		border-bottom-left-radius: 6px;
		color: #1a1a1a !important;
	}
	.tab-vertical.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
		border-top-left-radius: 6px;
		border-bottom-left-radius: 6px;
	}
	.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
		color: #363636 !important;
	}
}

