// .ant-switch-checked {
// 	background-color:  $bg-color-blue7;
// }
// .ant-radio-checked .ant-radio-inner {
// 	border-color: $bg-color-blue7;
// }
// .ant-radio-inner {
// 	width: 14px;
// 	height: 14px;
// 	&::after {
// 		background-color: $bg-color-blue7;
// 	}
// }



// .ant-radio-wrapper:hover .ant-radio,
// .ant-radio:hover .ant-radio-inner,
// .ant-radio-input:focus + .ant-radio-inner {
// 	border-color: $bg-color-blue7;
// }
// span.ant-radio + * {
// 	font-size: $txt-size-h7;
// 	color: $txt-color-black;
// }

// .ant-checkbox {
// 	&.ant-checkbox-indeterminate {
// 		&.ant-checkbox-checked {
// 			& .ant-checkbox-inner {
// 				background-color: #fff !important;
			
// 				&::after {
// 					background-color: #3340b6 !important;
// 				}
// 			}
// 		}
// 	}
// 	&.ant-checkbox-checked {
// 		&.ant-checkbox-disabled {
// 			& .ant-checkbox-inner {
// 				background-color: #f5f5f5;
//     		border-color: #d9d9d9;
// 			}
// 		}
// 		& .ant-checkbox-inner {
//       border-color: #3340b6;
// 			background-color: #3340b6;
// 		}
// 	}
// }
// .ant-checkbox-wrapper:hover .ant-checkbox-inner,
// .ant-checkbox:hover .ant-checkbox-inner,
// .ant-checkbox-input:focus + .ant-checkbox-inner {
// 	border-color: #3340b6;
// }
// .ant-checkbox-checked::after {
// 	border: 1px solid #3340b6;
// }

.form-filter {
	.ant-form-item {
		margin: 0;
		color: #1a1a1a;
		font-size: 14px;
		line-height: 22px;
		font-family: 'Roboto', sans-serif;
		display: flex;
		align-items: center;
		width: 100%;
		& .ant-form-item-row {
			width: 100%;
		}
		.ant-form-item-label {
			margin-right: 4px;
			display: flex;
			align-items: center;
			& > label {
				font: inherit;
				height: 22px;
				color: #1a1a1a;
				&::after {
					margin: 0;
				}
			}
		}
		input {
			font: inherit;
			border-radius: 4px;
			&::placeholder {
				color: #bdbdbd;
			}
			padding: 2px 9px;
			&[type='date'] {
				border: none;
			}
		}

		& .ant-input-affix-wrapper {
			padding-top: 0;
			padding-bottom: 0;
			height: 32px;
			border-radius: 4px;
		}


		&.status-cutoff,
		&.query {
			& .ant-form-item-label {
				flex-shrink: 0;
			}
		}

		.ant-picker-range {
			padding: 0 9px;
			border-radius: 4px;
			font: inherit;
		}
	}
	& .ant-row {
		& .type-search {
			display: flex;
			flex-wrap: nowrap;
			align-items: center;
			gap: 12px;
			font-family: 'Roboto', sans-serif;
			font-size: 14px;
			line-height: 22px;
			& .ant-select {
				flex-basis: 23%;
			}
			& .ant-form-item {
				&:first-of-type {
					margin-right: 4px;
				}
				flex-basis: 100%;
				flex-direction: row-reverse;
				gap: 12px;
			}
		}
	}

	& .ant-select:not(.ant-select-customize-input) .ant-select-selector {
		box-shadow: none;
		border-radius: 4px;
		// line-height: 22px;
		// padding-top: 3px;
		// padding-bottom: 3px;
		// height: 28px;
		font: inherit;
		font-size: 14px;
		& .ant-select-selection-item {
			display: flex;
			align-items: center;
			gap: 4px;
			font-size: 14px;
			.ant-image {
				display: flex;
				align-items: center;
			}
		}
		.ant-select-selection-placeholder {
			display: flex;
			align-items: center;
		}
	}
}

.ant-form-item {
	margin-bottom: 8px;
}

.clean-filter {
	> span:first-child {
		transform: rotate(-90deg);
	}
	padding-right: 0;
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	box-shadow: none;
	&.ant-btn:not([disabled]):hover {
		color: #1a1a1a;
	}
	&[ant-click-animating-without-extra-node='true'] {
		&::after {
			animation: fadeEffect 3s cubic-bezier(0.08, 0.82, 0.17, 1),
				waveEffect 0.8s cubic-bezier(0.08, 0.82, 0.17, 1);
			opacity: 0.2;
			box-shadow: 0px 0px 0px 3px #ced1f3 !important;
		}
		& .anticon-redo {
			transition: 3s all ease-in-out;
			transform: rotate(270deg) !important;
		}
	}
	&.ant-btn:not([disabled]):active,
	&:focus {
		color: #707070;
	}

	&:hover {
		color: #1a1a1a;
	}
}
