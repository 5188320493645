.upload-common {
  &-validate-error {
    div.ant-upload.ant-upload-select-picture-card {
      border-color: #ff4d4f;
    }
  }

  .ant-upload-list-picture-card {
    %previewImage {
      width: 80px;
      height: 80px;
      margin-right: 12px;
    }
    .ant-upload-list-picture-card-container {
      @extend %previewImage;
      user-select: none;

      div[role='button'] {
        height: 100%;
      }

      .ant-upload-list-item {
        padding: 0;
        border-radius: 4px;

        &:hover {
          border-color: rgba(0, 0, 0, 0.45);
          transition: all 0.3s;
        }

        .ant-upload-list-item-info {
          border-radius: 4px;
        }
      }

      .ant-upload-list-item-uploading {
        .ant-upload-list-item-info {
          height: 100%;

          .ant-upload-list-item-thumbnail {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }

  .ant-upload-list-item-actions {
    display: flex;
    align-items: flex-end;
    > a {
      display: flex;
      align-items: flex-end;
    }
  }

  .ant-upload.ant-upload-select-picture-card {
    @extend %previewImage;
    border-radius: 4px;
    border-color: #3340b6;

    .anticon {
      color: #3340b6;
      // font-size: 22px;
    }
  }
}
.upload-error-message.ant-form-item-explain-error {
  margin-top: -8px;
}

.ant-modal-wrap {
  &.upload-preview-modal {
    overflow: hidden;

    .ant-modal-content {
      .ant-modal-header {
        height: 52px;
        box-shadow: inset 0px -1px 0px #EBEBEB;

        .ant-modal-title {
          font-weight: 500;
          font-size: 16px;
          display: flex;
          justify-content: center;
        }
      }

      .ant-modal-body {
        padding: 20px;

        .ant-modal-close-x {
          margin-top: 8px;
        }

        img {
          max-height: calc(100vh - 200px);
          object-fit: contain;
        }

        video {
          max-height: calc(100vh - 200px);
          width: 100%;
        }
      }
    }
  }
}

div[data-rbd-placeholder-context-id='1'] {
  display: none !important;
}
