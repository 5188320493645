.header-notification-popup {
  top: 40px !important;
  .notification-top {
    display: flex;
    justify-content: space-between;
    .top-title {
      color: #1a1a1a;
      font-size: 16px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 0 4px;
      .notice-top-badge {
        background-color: #3340B6;
        color: #fff;
        border-radius: 20px;
        text-align: center;
        height: 20px;
        font-size: 12px;
        line-height: 20px;
        padding: 0 10px;
      }
    }
    .mark-as-read-all {
      color: #008DF9;
      font-size: 12px;
      font-weight: 500;
      cursor: pointer;
    }
  }
  .ant-popover-inner {
    box-shadow: 0 6px 15px rgb(0 0 0 / 10%);
    border-radius: 6px;
    .ant-popover-inner-content {
      padding: 12px 16px 0 16px;
      width: 820px;
      max-height: 80vh;
    }
  }

  .notifications-tabs {
    font-family: 'Roboto', sans-serif;
    background-color: #fff;
    min-width: 600px;
    .ant-tabs-nav {
      margin: 0 0 12px 0;
    }

    .btn-notification-filter {
      background: #ECEDF8;
      border-radius: 4px;
      padding: 3px 12px;
      font-size: 12px;
      font-weight: 500;
      border: none;
      box-sizing: border-box;
      color: #3340B6;
      &:hover, &.active {
        background: #3340B6;
        color: #fff;
      }
    }

    .notification-badge {
      background: #F4F6F8;
      border-radius: 20px;
      height: 20px;
      display: inline-block;
      color: #707070;
      text-align: center;
      min-width: 20px;
      line-height: 20px;
      font-size: 12px;
      margin-left: 2px;
    }

    .ant-tabs-nav-list .ant-tabs-tab {
      padding: 8px;
      &.ant-tabs-tab-active {
        box-shadow: inset 0px -2px 0px #3340B6;
        z-index: 999;
        & .ant-tabs-tab-btn {
          color: #3340b6;
          font-weight: 500;
          font-size: 14px;
        }
        .notification-badge {
          background-color: #3340B6;
          color: #fff;
        }
      }
      .ant-tabs-tab-btn {
        color: #707070;
        font-size: 14px;
        &:hover {
          color: #3340B6;
        }
      }
    }

    .ant-tabs-nav-list {
      .ant-tabs-ink-bar {
        background-color: #3340B6;
      }
    }
  }

  .notification-empty-items {
    padding-bottom: 20px;
    .notification-empty-items-img {
      text-align: center;
      margin-bottom: 12px;
      img {
        width: 110px;
        height: 40px;
        object-position: center center;
        object-fit: cover;
        display: inline-block;
      }
    }

    .notification-empty-items-txt {
      font-size: 14px;
      color: #707070;
      text-align: center;
      font-weight: 400;
    }
  }

  .notification-welcome {
    padding-bottom: 20px;
    .notification-welcome-img {
      text-align: center;
      margin-bottom: 12px;
      img {
        width: 110px;
        height: 40px;
        object-position: center center;
        object-fit: cover;
        display: inline-block;
      }
    }

    .notification-welcome-txt {
      font-size: 14px;
      color: #707070;
      text-align: center;
      font-weight: 400;
    }
  }


  .notification-content-box {
    font-family: 'Roboto', sans-serif;
    background-color: #fff;
    font-size: 12px;
    .notice-context-buttons {
      display: flex;
      align-items: center;
      gap: 0 12px;
    }
    .notification-list {
      margin-top: 10px;
      max-height: 350px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 3px;
      }

      &::-webkit-scrollbar-thumb {
        background: rgba(196, 196, 196, 0.6);
        border-radius: 20px;
      }
    }
    .notification-item {
      padding: 10px 10px 10px 0;
      position: relative;
      border-bottom: 1px solid #ebebeb;
      .notification-item-inner {
        display: flex;
        gap: 8px;
      }
      .notification-img {
        border: none;
        &.default-img {
          position: relative;
          img {
            width: 70%;
            height: 70%;
            position: absolute;
            top: 5px;
            left: 6px;
          }
        }
      }
      &:last-child {
        border-bottom: none;
      }
      &:hover {
        cursor: pointer;
      }
      &.notification-item-unread {
        .notification-item-title {
          color: #1A1A1A;
          font-weight: 700;
        }
        .notification-unread-status {
          position: absolute;
          top: -6px;
          right: 0;
        }
        .notification-unread-status-icon {
          color: #F54255;
          font-size: 28px;
          margin-right: 10px;
        }
      }
    }
    .notification-item-title {
      color: #1a1a1a;
      font-size: 12px;
      max-width: 488px;
    }
    .notification-item-time {
      color: #BDBDBD;
      font-size: 12px;
    }

    .notification-view-all {
      color: #008DF9;
      font-size: 12px;
      font-weight: 500;
      cursor: pointer;
      text-align: center;
      line-height: 20px;
      padding: 16px 10px 5px 10px;
    }
  }
  .ant-popover-arrow {
    display: none;
  }
}

.spin-container {
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
}
