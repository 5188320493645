.faq-modal {
  height: 500px;
  & .ant-modal-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    & .ant-modal-body {
      padding-top: 0;
    }
  }

  & .ant-modal-header {
    border-bottom: none;
    padding-top: 12px;
    padding-bottom: 12px;
    & .ant-modal-title > span {
      color: #1a1a1a !important;
    }
  }
  & .ant-modal-footer {
    padding-top: 12px !important;
  }
  br {
    content: '';
    display: block !important;
    margin-bottom: 3px;
  }
}

.supplier-card-head {
  & .ant-card-body {
    padding: 12px;
  }
  & .ant-card-head {
    padding-left: 12px;
    padding-right: 12px;
    & .ant-card-head-title {
      padding-top: 12px;
      padding-bottom: 12px;
    }
  }
}
.supplier-card {
  cursor: pointer;
  & .ant-card-head {
    border-bottom: none;
    padding-left: 12px;
    padding-right: 12px;
    & .ant-card-head-title {
      padding-top: 12px;
      padding-bottom: 12px;
    }
  }
  & .ant-card-body {
    padding: 12px;
    padding-top: 0;
  }
  &__collapse .ant-card-body {
    padding-bottom: 0;
  }
}

.empty-faqs {
  height: 500px;
  width: 100%;
}

// .btn-df-tertiary:hover {
//   background-color: #3340B6 !important;
//   color: white !important;
//   border: none;
// }
