.steps-header {
	background-color: white;
	position: fixed !important;
	z-index: 2;

	& .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon .ant-steps-icon {
		color: #1a1a1a;
	}
	&.ant-card {
		border-radius: 6px !important;
		margin-right: 12px;
		// box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.1) !important;
		& .ant-card-body {
			padding: 15px 12px;
			& .ant-steps {
				line-height: 22px;
				color: #1a1a1a;
				& .ant-steps-item-title { 
					line-height: 27px;
					font-size: 14px;
				}
			}
		}

		& .line {
			border-top: 1px solid #EBEBEB;
		}
	}
	position: relative;

	&::before {
		content: "";
		position: absolute;
		top: -24px;
		left: 0;
		width: 100%;
		height: 24px;
		background-color: #EDF3F7;
	}
}
.steps-header-content {
	background-color: #ffffff;
}
.shopping-cart-steps {
	.ant-steps-item-title {
		font-family: 'Roboto', sans-serif;
		font-size: 14px;
		color: #1a1a1a !important;
	}
	.ant-steps-item-wait .ant-steps-item-icon {
		border-color: #bdbdbd;
		color: #bdbdbd;
	}
}

.skeleton-cart {
	z-index: 2;
	& .ant-card-body {
		padding: 16px 12px;
		& .skeleton-item {
			margin-bottom: 12px;
			border-bottom: 1px solid #EBEBEB;
			padding: 12px 0;
		}
	}
	& .ant-card-head {
		padding: 0 12px;
	}
}

.success-peer-payment-shipment {
	& .ant-modal-content {
		border-radius: 12px;
		background-color: #FFF9DF;
	}
}
