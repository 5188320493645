.ant-tag {
	background: #fcd535;
	border-radius: 16px;
	border: none;
	font-weight: 500;
	font-size: 14px;
	padding: 4px 14px;
}
.tag-rounded {
	color: #1a1a1a;
	border: 1px solid #fcd535 !important;
	background-color: #fff9df;
	border-radius: 16px;
}

.tag-disable {
	color: #1a1a1a;
	background-color: #f6f6f6;
}

.tag-gray {
	color: #1a1a1a;
	background-color: #ebebeb;
}

.tag-dark {
	color: #e6e6e6;
	background-color: #4d545f;
}

.tag-dark-disable {
	color: #e6e6e6;
	background-color: #32373e;
}

