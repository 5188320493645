#sell-info {
  .table-header {
    min-height: 30px;
    background: #ecedf8;
    border-radius: 4px 4px 0px 0px;
    margin: 0 !important;

    .table-cell {
      flex: 1;
      font-weight: 500;
      font-size: 12px;
      padding-left: 12px;
      padding-right: 12px;
      line-height: 20px;
      color: #1a1a1a;
      display: flex;
      align-items: center;

      .ant-typography {
        margin: 0;
        padding: 0 !important;
        height: 100%;
        display: flex;
        align-items: center;
        word-break: break-word;

        textarea {
          border-radius: 4px;
        }
      }

      &.img-col {
        justify-content: center;
        max-width: 210px;
      }

      &.action {
        max-width: 16px;
      }
    }
  }

  .table-row {
    min-height: 70px;
    margin: 0 !important;
    border-bottom: 1px solid #f5f5f5;

    .table-cell {
      margin-top: 22px;
      margin-bottom: 20px;
      flex: 1;
      padding: 0 12px;

      .ant-form-item-has-error {
        margin-bottom: -10px;
      }

      .ant-row {
        width: 100%;
      }

      &.img-col {
        max-width: 210px;
        margin-top: 12px;
        margin-bottom: 12px;
        justify-content: center;
        display: flex;

        .ant-row {
          .ant-form-item-control-input-content {
            display: flex;
            align-items: center;
            flex-direction: column;

            .ant-upload-picture-card-wrapper {
              width: 50px;
              height: 50px;
            }
          }
        }

        .ant-form-item-explain-error {
          text-align: center;
          margin-top: 0;
        }
      }

      &.action {
        max-width: 16px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
