// $color-FF4559: #ff4559;
// $color-27AE61: #27ae61;
.badges {
	color: #363636;
	font-weight: 500;
	border-radius: 16px;
	text-align: center;
	padding: 4px 12px;
	font-family: "Roboto";
	&-bgc__red {
		background-color: $color-FF4559;
	}
	&-bgc__gray {
		color: #b1b1b1;
		background-color: $color-f5f5f5;
	}
	&-bgc__green {
		background-color: $color-27AE61;
	}
	&-bgc__blue {
		background-color: $color-339DFF;
	}
	&-bgc__FDB924 {
		background-color: $color-FDB924;
	}
	&-bgc__FCD535 {
		background-color: $color-FCD535;
	}
}

