.left-nav-bar {
  flex-direction: column;
  display: flex;
  margin-top: 29px;
  width: 202px;
  min-width: 170px;
  z-index: 1;
  height: 180px;
  position: sticky;
  top: 106px;

  > a {
    border-radius: 6px 0px 0px 6px;
    margin-bottom: 12px;
    background-color: #fff;
    display: flex;
    align-items: center;
    padding: 11.5px 0 12px 11.5px;
    color: #1a1a1a;
    font-weight: 500;
    font-size: 16px;

    &.active {
      background-color: #ecedf8;
      color: #3340b6;
    }
    &:hover {
      color: #3340b6;
    }
  }
}
