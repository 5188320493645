.add-supplier {
  & .ant-modal-content {
   & .ant-modal-body {
     padding-top: 0;
     padding-bottom: 12px;
   }

   & .ant-modal-header {
     border-bottom: none;
   }

   & .ant-modal-title {
     font-family: 'Roboto', sans-serif;
     font-weight: 500;
     font-size: 14px;
     color: #1a1a1a;
   }

   & .ant-modal-footer {
     padding-bottom: 12px;
   }
  }
  .ant-input-affix-wrapper {
    padding: 0 !important;
  }

  .ant-input-password {
   input {
     padding: 5px 0 5px 12px !important;
   }
   .ant-input-suffix {
     margin-right: 12px;
   }
  }
   a{
     color: #3340B6;
     &:hover  {
       color: #3340B6;
     }
   }
 }
 .phone {
   position: relative;
   .messages-validate {
     position: absolute;
     bottom: -25px;
     left: 0;
   }
 }
 .btn-df-tertiary:hover {
   box-shadow: 0px 0px 0px 3px #CED1F3;
   border: none;
 }
