.datepicker-range {
	border: 1px solid #d9d9d9;
	border-radius: 8px;
	&:hover {
		border-color: #fcd535 !important;
		// box-shadow: 0 0 0 2px RGB(24 144 255/20%);
	}
	&:focus-within {
		border-color: #fcd535 !important;
		box-shadow: 0 0 0 2px #fcd53533;
		outline: 0;
	}
	.datepicker-field {
		position: relative;

		.form-label {
			position: absolute;
			top: 50%;
			left: 12px;
			transform: translateY(-50%);

			user-select: none;
			pointer-events: none;
			transition: 0.25s ease;
		}

		.ant-picker {
			padding-top: 9px;
			padding-bottom: 9px;

			&:focus,
			&:focus-within,
			&.input-has-value {
				padding-top: 9px;
				padding-bottom: 9px;

				.ant-picker-suffix {
					margin-top: -3px;
				}
			}

			.ant-picker-input input:not(:placeholder-shown) + .form-label,
			&:focus-within + .form-label,
			&.input-has-value + .form-label {
				font-size: 12px;
				font-weight: 400;
				top: 0px;
				background: #ffff;
				padding: 0px 4px;
			}

			&.input-has-value .ant-picker-clear {
				margin-top: -1px;
			}
		}
	}
}

.ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(
		.ant-picker-cell-range-end
	):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end)
	.ant-picker-cell-inner {
	background: #fff9df;
}

.datepicker-range-dark {
	border: 1px solid #c0c0c0;
	border-radius: 8px;
	&:hover {
		border-color: #fcd535 !important;
		// box-shadow: 0 0 0 2px RGB(24 144 255/20%);
	}
	&:focus-within {
		border-color: #fcd535 !important;
		box-shadow: 0 0 0 2px #fcd53533;
		outline: 0;
	}
	.ant-picker-input-placeholder > input {
		color: #666666 !important;
	}
	.datepicker-field {
		position: relative;

		.form-label {
			position: absolute;
			top: 50%;
			left: 12px;
			transform: translateY(-50%);

			user-select: none;
			pointer-events: none;
			transition: 0.25s ease;
		}

		.ant-picker {
			padding-top: 9px;
			padding-bottom: 9px;
			&-date-panel {
				background-color: #32373e !important;
			}
			&:focus,
			&:focus-within,
			&.input-has-value {
				padding-top: 9px;
				padding-bottom: 9px;

				.ant-picker-suffix {
					margin-top: -3px;
				}
			}
			&-input > input {
				color: #e6e6e6;
			}
			.ant-picker-input input:not(:placeholder-shown) + .form-label,
			&:focus-within + .form-label,
			&.input-has-value + .form-label {
				font-size: 12px;
				font-weight: 400;
				top: 0px;
				background: #121518;
				padding: 0px 4px;
			}
			&-clear {
				background: none;
				color: #e6e6e6;
			}
			&.input-has-value .ant-picker-clear {
				margin-top: -1px;
			}
		}
	}
}

.calendar-dark {
	.ant-picker-panel-container {
		background-color: #32373e !important;
	}
	.ant-picker-header-view {
		color: #e6e6e6 !important;
	}
	.ant-picker-today-btn {
		color: #e6e6e6;
	}
	.ant-picker-cell {
		color: #666666;
	}
	.ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(
			.ant-picker-cell-range-end
		):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end)
		.ant-picker-cell-inner {
		color: #1a1a1a;
		background-color: #fcd535;
	}
	.ant-picker-month-btn,
	.ant-picker-year-btn {
		color: #e6e6e6 !important;
	}
	td.ant-picker-cell.ant-picker-cell-in-view {
		color: #e6e6e6;
	}

	.ant-picker-content th {
		color: #e6e6e6;
	}
	.ant-picker-header button {
		color: #666666;
	}
	.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
		color: #1a1a1a;
	}
	// .ant-picker-cell {
	// 	padding: 3px 0;
	// 	color: rgba(0, 0, 0, 0.25) !important;
	// 	cursor: pointer;
	// }
}

