.ant-modal-content {
  padding: 0 16px !important;

  .ant-modal-header {
    padding: 16px 0;

    .ant-modal-title {
      span {
        color: $txt-color-black;
        font-size: $txt-size-h5;
        font-weight: 500;
      }
    }
  }

  .ant-modal-close-x {
    color: $txt-color-black;
  }

  .ant-modal-body {
    padding: 16px 0;
    font-size: $txt-size-h7;
  }

  .ant-modal-footer {
    padding: 0 0 24px 0;
    border: 0;

    .btn_cancel {
      margin-top: 5px;
      border-radius: 4px;
    }
  }
}

.ant-modal-custom {
  ::-webkit-scrollbar {
    width: 2px;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(236, 236, 236, 0.85);
    border-radius: 10px;
  }

  .ant-modal-content {
    padding: 0 12px;

    .ant-modal-header {
      padding: 16px 0;

      .ant-modal-title {
        span {
          color: black !important;
          font-size: $txt-size-h7;
          font-weight: 500;
        }
      }
    }

    .ant-modal-body {
      //padding: 16px 0;
      padding-top: 0;
      font-size: $txt-size-h7;
    }

    .ant-modal-footer {
      padding: 0 0 24px 0;
      border: 0;
      text-align: left;

      .btn_cancel {
        border-radius: 4px;
        margin-top: 5px;
      }
    }
  }
}

.modal-without-header-padding {
  .ant-modal-header {
    padding: 12px 0;
    &.log-order {
      padding: 8px 12px 12px;
    }
  }
  & .ant-modal-content {
    max-height: calc(100vh - 120px) !important;
  }
}

.modal-common {
  .fa-light {
    font-weight: bold;
    font-size: 14px;
  }

  .ant-modal-content {
    border-radius: 4px;

    .ant-modal-close-x {
      i {
        font-weight: bold;
      }
    }

    .ant-modal-header {
      padding: 12px 0 16px 0;
      border-bottom: unset;

      .ant-modal-title {
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
      }
    }

    .ant-modal-body {
      padding: 0;

      .ant-form-item {
        .ant-form-item-label {
          label {
            font-size: 14px;
          }
        }

        .ant-form-item-explain {
          min-height: unset;
          color: #707070;
          font-size: 14px;

          i {
            font-size: 10px;
          }
        }
      }
    }

    .ant-modal-footer {
      padding-bottom: 16px;
    }
  }
}

.dropdown-popup-custom {
  padding: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  border-radius: 2px;

  .ant-cascader-menus {
    .ant-cascader-menu {
      height: 400px;
      min-width: 200px;
      border-radius: 4px;

      &:has(.ant-cascader-menu-item-expand-icon) {
        padding-left: 0;
      }

      .ant-cascader-menu-item {
        padding: 0;
        height: 46px;

        .ant-cascader-menu-item-content {
          font-size: 14px;
          height: 100%;
          border-bottom: 1px solid #ebebeb;
          display: flex;
          align-items: center;
          margin-left: 12px;
          padding-right: 12px;
        }

        .ant-cascader-menu-item-expand-icon {
          display: flex;
          align-items: center;
          height: 100%;
          margin-left: 0;
          border-bottom: 1px solid #ebebeb;
          margin-right: 10px;

          span {
            font-size: 14px;
          }
        }

        &.ant-cascader-menu-item-active {
          background-color: #fff;
          color: #3340b6;
          font-weight: 400;

          .ant-cascader-menu-item-expand-icon {
            span {
              color: #3340b6;
            }
          }
        }
      }
    }
  }

  .ant-cascader-menu-empty {
    .ant-cascader-menu {
      height: unset;

      .ant-cascader-menu-item {
        padding: unset;
        height: unset;

        .ant-cascader-menu-item-content {
          display: unset;
        }
      }
    }
  }

  &.ant-select-dropdown {
    // .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    //   background-color: #fff;
    // }
    // .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    //   background-color: #fff;
    // }

    .ant-select-item-option-selected {
      color: #3340b6;
      font-weight: 400;
    }

    .ant-select-item,
    .ant-select-item-option {
      padding: 0;
      font-size: 14px;
      background-color: #fff;

      &-active {
        background-color: #f5f5f5;
      }

      &-selected {
        background-color: #fff;
      }

      .ant-select-item-option-content {
        height: 100%;
        padding: 10px 0;
        border-bottom: 1px solid #f2f2f2;
        margin: 0 12px;
      }
    }

    .rc-virtual-list-scrollbar {
      width: 4px !important;
    }
  }

  /* total width */
  ::-webkit-scrollbar {
    background-color: #fff;
    width: 12px;
  }

  /* background of the scrollbar except button or resizer */
  ::-webkit-scrollbar-track {
    background-color: #fff;
  }

  /* scrollbar itself */
  ::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 4px solid #fff;
  }

  /* set button(top and bottom of the scrollbar) */
  ::-webkit-scrollbar-button {
    display: none;
  }
}

.ant-modal .ant-modal-close {
  top: 0;
}
