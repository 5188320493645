.ant-switch {
	background-color: #ebebeb;
}

.ant-switch-checked {
	background-color: #fcd535 !important;
}

button.ant-switch.switch-dark {
	background-color: #4d545f ;
}

