.divider-bd-custom {
	border: 1px solid $bd-divider-success-box;
}
.faq-modal {
	.ant-modal-header {
		padding-top: 12px;
		padding-bottom: 12px;
		& .ant-modal-title > span {
			color: #1a1a1a !important;
		}
	}
	.ant-modal-footer {
		padding-bottom: 12px;
	}
}

.ant-table-empty .ant-table-tbody > tr.ant-table-placeholder > td {
	border-bottom: none;
	padding-bottom: 4px;
}
// .ant-select:not(.ant-select-customize-input) .ant-select-selector {
// 	border-radius: 4px;
// 	border: 1px solid #c7c7c7;
// }

.ant-modal-content {
	.ant-modal-header {
	}
	& .ant-modal-footer {
		& > button {
			// padding: 5px 16px;
			text-transform: capitalize;
		}
	}
	& .ant-modal-footer {
	}
}

.ant-empty-image {
	height: auto;
}

.data-row_extended {
	// pointer-events: none;
	cursor: pointer;

	&:hover > td {
		background-color: none !important;
	}
}

.ant-breadcrumb {
	font-size: 14px;
	line-height: 22px;

	& > ol {
		align-items: center;
		& > li {
			display: flex;
			align-items: center;
			& .ant-breadcrumb-separator {
				margin: 0 4px;
			}
			&:last-of-type {
				margin-right: 4px;
				& .ant-breadcrumb-link * {
					color: #1a1a1a;
					font-weight: 500;
				}
				& .ant-breadcrumb-separator {
					display: none;
				}
			}
		}
	}
}

.ant-popover-inner-content {
	padding: 12px;
	color: #1a1a1a;
}

.ant-list-item-meta-avatar {
	margin-right: 12px;
}

.ant-btn-text:hover,
.ant-btn-text:focus {
	background: transparent;
	color: #f59f32;
}

.ant-input {
	background: #ffffff;
	border: 1px solid #c7c7c7;
	border-radius: 4px;
	font-weight: 400;
	&:placeholder {
		color: #707070;
		font-size: 12px;
	}
}
// .ant-picker {
// 	border-radius: 4px;
// 	border: 1px solid #c7c7c7;
// 	padding-top: 5px;
// 	padding-bottom: 5px;
// }

.gsa-tooltip {
	.ant-tooltip-arrow-content::before {
		display: none;
	}
	& .shoppe-gsa-link a {
		color: #1a1a1a;
		&:hover {
			color: #fbb739;
		}
	}
}

.ant-menu-inline,
.ant-menu-vertical {
	border-right: 0;
}

.ant-menu-vertical {
	&.ant-menu-inline-collapsed {
		padding-left: 8px !important;
		padding-right: 8px !important;
		& .icon {
			padding-top: 8px;
			padding-bottom: 8px;
			border-radius: 6px;
		}
	}
}
// .ant-menu-submenu.ant-menu-submenu-inline {
// 	position: relative;
// 	&::after {
// 		content: '';
// 		position: absolute;
// 		width: 100%;
// 		border-bottom: 1px solid #FFD78A;
// 		bottom: 0;
// 		left: 0;
// 	}
// }

.ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open .ant-menu-item {
	padding-left: 25px !important;
	padding-right: 0 !important;
}
ant-menu-submenu-title > ant-menu-submenu-arrow {
	display: none;
}
.ant-input-affix-wrapper {
	border-radius: 4px;
	border: 1px solid #c7c7c7;
}



.link-override {
	&:hover {
		color: #fbb739;
	}
}

.ant-upload-list-item-actions {
	display: flex;
	align-items: baseline;

	& .anticon.anticon-eye {
		vertical-align: 0;
		height: 18.858px;
		line-height: 1;
		& > svg {
			height: 18.858px;
		}
	}
}
.ant-upload-list-item-card-actions-btn {
	& .anticon.anticon-delete {
		height: 18.858px;
		& > svg {
			height: 18.858px;
		}
	}
}

// .ant-picker-input > input:placeholder-shown, .ant-input:placeholder-shown {
// 	color: #707070;
// 	padding-left: 12px;
// 	padding-right: 12px;
// 	line-height: 20px;
// }

.ant-table-measure-row {
	// display: none;
}

.ant-table-title {
	border-radius: 6px 6px 0 0;
	padding: 16px 8px;
}

.ant-table-cell.ant-table-selection-column {
	border-spacing: initial;
}

// .ant-select-selection-placeholder {
// 	// font-size: 14px;
// 	line-height: 32px;
// }

// .ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
// 	padding-left: 12px;
// 	padding-right: 12px;
// 	color: #707070;
// 	font-size: 14px;
// }

.ant-layout-sider-trigger {
	display: flex;
	justify-content: center;
	border-radius: 0px 8px 8px 0px;
}

.ant-layout.ant-layout-has-sider {
	// overflow-y: scroll;
	min-height: 100vh;
}

.custom-menu-selected {
	.ant-menu-item-icon {
		background-color: #fbb739 !important;
		border-radius: 20px !important;
	}
	// background-color: red;
}

.ant-layout.ant-layout-has-sider {
	// overflow-y: scroll;
	min-height: 100vh;
}

.ant-progress-steps-item {
	border-radius: 4px;
	width: 5px !important;
}

