.package-data {
	tr {
		> td {
			padding: 5px 16px 11px 16px !important;
			&:first-of-type {
				padding-left: 12px !important;
			}
		}
	}
}
.package-timeline {
	padding: 0 40px;
	.ant-steps-item {
		&.ant-steps-item-finish,
		&.ant-steps-item-wait {
			.ant-steps-item-tail {
				margin: 0 auto !important;
				bottom: 1px !important;
				top: unset !important;
				&::after {
					height: 0 !important;
					margin-left: 15px !important;
				}
			}
			.ant-steps-item-container {
				display: flex;
				flex-direction: column-reverse;
				justify-content: space-between;
				gap: 2px;
				height: 100%;
				.ant-steps-item-content {
					margin: 0;
					.ant-steps-item-title {
						display: flex;
						align-items: flex-start;
					}
				}
			}
		}
		&.ant-steps-item-finish {
			.ant-steps-item-title {
				span {
					color: #1a1a1a;
				}
			}
		
			i {
				color: #c7c7c7;
			}
			.ant-steps-item-tail {
				&::after {
					border: 1px solid #c7c7c7;
				}
			}

			&.tail-none {
				.ant-steps-item-tail {
					display: none;
				}
				&.last-tail {
					i {
						color: #fbb739;
					}
				}
			}
		}
		&.ant-steps-item-wait {
			&.tail-none {
				.ant-steps-item-title > span,
				.description > span {
					color: #bdbdbd;
				}
				.ant-steps-item-tail {
					display: none;
				}
				.ant-steps-item-icon {
					visibility: hidden;
				}
			}
			.ant-steps-item-tail {
				&::after {
					// border: 1px solid #fbb739;
					border: 1px solid #c7c7c7;
				}
			}
		}
	}
	.ant-steps-item-content {
		width: 100% !important;
		text-align: left !important;
	}
	.ant-steps-item-icon {
		margin: 0 !important;
		width: 10px !important;
		height: 10px !important;
	}
}