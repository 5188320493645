@import '../../resources/styles/helpers/mixins';

.price-config {
  font-family: 'Roboto', sans-serif;

  .ant-tabs-card {
    > .ant-tabs-nav {
      margin: 0;
    }

    .ant-tabs-nav-wrap {
      height: 34px;
      border-radius: 6px 6px 0 0;
      -webkit-border-radius: 6px 6px 0 0;
      -moz-border-radius: 6px 6px 0 0;
      background: #fff;
    }

    .ant-tabs-nav-list {
      .ant-tabs-tab {
        background: #ffffff;
        border: transparent !important;
        padding: 6px 32px;

        &.ant-tabs-tab-active {
          background: #ecedf8;
        }

        .ant-tabs-tab-btn {
          color: #3340b6;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          border-radius: 12px 12px 0 0;
          -webkit-border-radius: 12px 12px 0 0;
          -moz-border-radius: 12px 12px 0 0;
        }

        &-active {
          background-color: #ecedf8;
        }
      }
    }

    .ant-tabs-content-holder {
      background: #ffffff;
      box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
      border-radius: 0 0 6px 6px;
    }
  }
  .shop-list-header {
    padding: 8px 16px 0 16px;
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    .title {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      color: #1a1a1a;
      text-transform: capitalize;
    }

    .badge {
      border-radius: 20px;
      -moz-border-radius: 20px;
      -webkit-border-radius: 20px;
      padding: 0 8px;
      background: #faa200;
      color: #ffffff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      font-size: 12px;
    }

    .m24-button {
      &:disabled {
        border: none;
      }
    }
  }

  .shop-list {
    padding: 12px;
    background-color: #ffffff;

    .ant-table-thead {
      height: 30px;

      .ant-checkbox-inner {
        &::after {
          left: 50%;
          top: 50%;
          @include css3-prefix(transform, translate(-50%, -50%));
          width: 5px;
          height: 2px;
          background: white;
        }
      }
    }

    thead[class*='ant-table-thead'] tr {
      border-radius: 4px 4px 0 0;
      -webkit-border-radius: 4px 4px 0 0;
      -moz-border-radius: 4px 4px 0 0;
    }

    thead[class*='ant-table-thead'] th {
      padding: 5px 12px;
      border: none;
      background-color: #f4f6f8;
      font-family: Roboto, sans-serif;
      font-weight: 500;
      font-size: 12px;
      &:before {
        opacity: 0;
      }
    }

    tbody {
      .ant-table-row {
        cursor: pointer;
      }
    }

    .ant-table-cell {
      padding: 12px 16px;
    }

    .ant-form-item {
      .ant-form-item-explain {
        color: #707070;
        font-size: 14px;

        .fa-circle-info {
          font-size: 12px;
        }
      }

      input {
        max-width: 400px;
      }
    }

    .custom-row-hover:hover td {
      background: #feefd8 !important;
    }

    .empty-description {
      display: flex;
      flex-direction: column;
    }

    .ant-checkbox-inner {
      width: 12px;
      height: 12px;

      &::after {
        width: 4px;
        height: 7px;
      }
    }
  }
}
