.custom-ant-pagination {
	.ant-pagination-item {
		border-radius: 6px !important;
	}
	.ant-pagination-item-active {
		font-weight: 500;
		color: black !important;
		background: #fcd353;
		border-color: #fcd535;
		& a {
			color: black !important;
		}
	}
	.ant-pagination-prev .ant-pagination-item-link {
		border-radius: 6px;
	}
}

.custom-ant-pagination-dark {
	.ant-select:not(.ant-select-customize-input) .ant-select-selector {
		background-color: #32373e;
		border-radius: 4px;
		color: white;
		border: 1px solid #32373e;
	}
	.ant-select-arrow .anticon {
		color: #5a5a5a;
	}
	.ant-pagination-prev:hover .ant-pagination-item-link {
		color: #e6e6e6;
		border-color: none;
	}
	.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
		border: none;
	}
	.ant-pagination-disabled .ant-pagination-item-link {
		color: #ffffff;
		background-color: #4d545f;
		border: 1px solid #32373e;
	}
	.ant-pagination-item-link {
		color: #ffffff !important;
		border: none !important;
		background-color: #32373e;
		border-radius: 6px !important;
	}
	& .ant-pagination-item-active {
		border: none;
	}
	.ant-pagination-item {
		border: none;
		border-radius: 6px;
		background-color: #32373e;
	}
	.ant-pagination-item:hover {
		border: none !important;
	}
	.ant-pagination-item a {
		color: #ffffff;
	}
	.ant-pagination-item:hover a {
		border: none !important;
		// color: #ffffff;
	}

	.ant-pagination-item-active {
		background-color: #fcd535;
		//border: 1px solid #CC8700;
		a {
			color: #32373e;
			:active {
				color: white;
			}
		}
	}
	.ant-pagination-next:hover button {
		border-color: none !important;
	}
}

