.ant-radio {
	line-height: 22px;
	top: 2px;
}
span.ant-radio + * {
	padding-right: 4px;
	padding-left: 4px;
}
.ant-radio-inner::after {
	color: #fcd535;
}
.radio-dark {
	.ant-radio-checked {
		.ant-radio-inner {
			background-color: #121518;
		}
	}
}

// .ant-radio-checked .ant-radio-inner {
// 	border-color: $bg-color-blue7;
// }
// .ant-radio-inner {
// 	width: 14px;
// 	height: 14px;
// 	&::after {
// 		background-color: $bg-color-blue7;
// 	}
// }

// .ant-radio-wrapper:hover .ant-radio,
// .ant-radio:hover .ant-radio-inner,
// .ant-radio-input:focus + .ant-radio-inner {
// 	border-color: $bg-color-blue7;
// }
// span.ant-radio + * {
// 	font-size: $txt-size-h7;
// 	color: $txt-color-black;
// }

