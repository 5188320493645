.select-light {
	.ant-select {
		position: relative;
	}
	.selected-label {
		position: absolute;
		top: 1px;
		font-size: 12px;
		// transform: translateY(-50%);
		color: gray;
		// user-select: none;
		// pointer-events: none;
		// transition: 0.25s ease;
		// z-index: 10;
	}
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
	.selected-label {
		position: absolute;
		top: -1px;
		margin: 0;
		padding: 0;
		background-color: white;
		font-size: 12px;
		transform: translateY(-50%);
		color: gray;
		-webkit-user-select: none;
		user-select: none;
		pointer-events: none;
		transition: 0.25s ease;
		z-index: 10;
		height: 23px;
		display: flex;
		align-items: center;
	}
}
.ant-select-selection-placeholder {
	visibility: unset !important;
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
	line-height: 35px !important;
	// padding-left: 10px;
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
	cursor: text;
	border-radius: 8px;
	height: 38px;
}
.ant-select-single .ant-select-selector .ant-select-selection-search {
	top: 4px;
}

.ant-select {
	&.select-dark {
		.ant-select-arrow {
			color: #bdbdbd;
		}
		.ant-select-selector {
			border: 1px solid #fcd535;
			background-color: #121518;
			color: white;
			.ant-select-selection-placeholder > span {
				background-color: #121518 !important;
				color: #7d7e7e !important;
			}
		}

		// .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
		// 	.selected-label {
		// 		position: absolute;
		// 		top: -1px;
		// 		margin: 0;
		// 		padding: 0;
		// 		background-color: black;
		// 		font-size: 12px;
		// 		transform: translateY(-50%);
		// 		color: gray;
		// 		-webkit-user-select: none;
		// 		user-select: none;
		// 		pointer-events: none;
		// 		transition: 0.25s ease;
		// 		z-index: 10;
		// 		height: 23px;
		// 		display: flex;
		// 		align-items: center;
		// 	}
		// }
	}
}

