.product-for-sell-editing {
  display: flex;
  font-family: 'Roboto', sans-serif;
  color: #1a1a1a;

  .form-container {
    background-color: #fff;
    flex: 1;
    box-shadow: 0px 6px 15px rgb(0 0 0 / 10%);
    border-radius: 6px;
    overflow: hidden;

    section {
      width: 100%;
      padding: 12px;

      .section-header {
        width: 100%;
        text-align: center;
        border-bottom: 1px solid #ebebeb;
        line-height: 0.1em;
        margin: 10px 0 20px;
        text-transform: capitalize;

        > span {
          background: #fff;
          padding: 0 10px;
          font-weight: 500;
          font-size: 16px;
          color: #1a1a1a;
        }
      }

      .label {
        text-align: right;
        padding-top: 6px;
        color: #1a1a1a;
        font-size: 14px;

        &.label-require::before {
          display: inline-block;
          margin-right: 4px;
          color: #ff4d4f;
          font-size: 12px;
          font-family: SimSun, sans-serif;
          line-height: 1;
          content: '*';
        }
      }

      .upload-video {
        display: flex;

        .upload-input {
          width: 80px;
          margin-right: 12px;
        }
      }

      .input-hidden {
        .ant-form-item-control {
          height: 0;
          input {
            height: 0;
            padding: 0;
            border: none;
          }
        }
      }

      .ant-form-item {
        margin-bottom: 0;
      }

      %input-css {
        height: 32px;
        border-radius: 4px;
        border-color: #c7c7c7;
        font-size: 14px;
      }

      .ant-input-affix-wrapper {
        @extend %input-css;

        &:hover {
          border-color: #ffce63;
          border-right-width: 1px;
          z-index: 1;
        }

        &-focused {
          border-color: #ffce63;
        }

        .ant-input {
          height: unset;
          border: unset;
          font-size: 14px;
        }

        .ant-input-suffix {
          color: #bdbdbd;
          font-size: 12px;
        }
      }

      .profuct-link {
        @extend %input-css;
        border: 1px solid #c7c7c7;
        padding: 5px 8px 5px 12px;

        &:hover {
          border-color: #ffce63;
        }

        a {
          color: #1a1a1a;
          font-weight: 400;
          > span {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            display: block;
          }
        }

        .ant-typography-copy {
          margin-right: 4px;
          margin-left: 10px;
        }
      }

      .upload-classify-img {
        .ant-upload-list-picture-card-container {
          width: 50px;
          height: 50px;
          margin: 0;

          .ant-upload-list-item-info {
            border-radius: 4px;
          }
        }

        .ant-upload-select-picture-card {
          width: 50px;
          height: 50px;
          margin: 0;
        }

        .ant-upload-list-item-info::before {
          left: 0;
        }
      }

      .ant-form-item-control {
        .ant-form-item-explain {
          min-height: 0;
        }
      }

      .size-inputs {
        > .ant-col:last-child {
          display: flex;

          > .ant-row {
            flex: 1;
          }

          .size-unit {
            padding-top: 5px;
            padding-left: 12px;
          }
        }
      }

      &#other-section {
        padding-bottom: 60px;
      }
    }

    .transport-service {
      height: 32px;
      background-color: #ecedf8;
      font-weight: 700;
      margin-bottom: 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 12px;
      border-radius: 4px;

      .ant-switch {
        background: #bfbfbf;

        &.ant-switch-checked {
          background: #09b2aa;
        }
      }
    }

    .time-order {
      .ant-form-item-label {
        label {
          font-size: 14px;
        }
      }
    }
  }

  .save-button {
    position: fixed;
    right: 16px;
    bottom: 0;
    z-index: 1;
    height: 52px;
    background-color: #fff;
    width: 100%;
    display: flex;
    align-items: flex-end;
    width: calc(100vw - 322px);

    .button-container {
      border-top: 1px solid #ebebeb;
      flex: 1;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 12px;
    }
  }
}
