.address-btn-custom {
	background-color: transparent;
	:hover {
		color: rgba(17, 17, 17, 0.7);
		background-color: transparent;
	}
}

._address_count {
	border-radius: 5px;
	background-color: rgba(232, 229, 229, 0.92);
	width: 20px;
	display: inline-block;
	color: orange;
	text-align: center;
}
._address-table-custom thead > tr > th {
	background-color: #f5f5f5;
	border: none;
	height: 24px;
	padding-top: 5px;
	padding-bottom: 5px;
	color: #1a1a1a;
	//text-align: center;
}
._address-table-custom thead {
	height: 32px;
}
._address-table-custom {
	& .ant-modal-content {
		.ant-modal-header {
			padding: 12px 0;
			& .ant-modal-title > span.count {
				color: #faa200 !important;
			}
		}
		& .ant-modal-body {
			padding-top: 0;
		}
		& .ant-modal-footer {
			padding-bottom: 12px;
		}
	}
}
._address-table-custom tbody > tr > td {
	background-color: white;
	border: none;
	height: 24px;
	padding-top: 12px;
	padding-bottom: 12px;
	color: #1a1a1a;
}
._address-create-modal {
	.ant-modal-content {
		.ant-modal-header {
			padding-top: 12px;
			padding-bottom: 12px;
      border: none;
			& .ant-modal-title {
				color: #1a1a1a;
        font-size: 16px;
        line-height: 24px;
			}
		}
    & .ant-modal-body {
			padding-top: 0;
			padding-bottom: 0;
			// height: 480px;
			& .ant-input, & .ant-select, & .ant-select-item {
				font-size: 14px;
				color: #1a1a1a;
			}
		}
	}
	& .ant-modal-footer {
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}
}

.address-table {
	& .ant-table-thead {
		tr > th {
			&.ant-table-cell {
				font-family: Roboto;
				font-weight: 500;
				font-size: 14px;
				line-height: 22px;
				background-color: #f4f6f8;
				padding: 6px 16px;
				border-bottom: none;
				color: #1a1a1a;
				white-space: nowrap;
				&::before {
					width: 0 !important;
				}
				&.ant-table-row-expand-icon-cell {
					padding: 0 !important;
					width: 0 !important;
					padding-left: 12px;
				}
			}
		}
	}
}

